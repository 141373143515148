<template>
  <el-image-viewer
    :z-index="3000"
    :urlList="srcList"
    :on-close="closeViewer"
  ></el-image-viewer>
</template>

<script>
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  export default {
    components:{
      ElImageViewer
    },
    props:{
      srcList:{
        type: Array,
        default: () => []
      }
    },
    methods:{
      closeViewer(){
        this.$emit('update:showPreviewImg',false)
      }
    }
  }
</script>

<style scoped>

</style>
