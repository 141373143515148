<template>
	<div class="inner">
		<div class="container">
			<div class="title-btn blf ft24 tc">留言反馈</div>
			<div class="feedback-form">
				<div class="tc ft24 pb40">反馈类别</div>
				<div class="tc news-nav ft16">
					<el-row :gutter="12">
						<el-col :span="6" v-for="(item,index) in feedbackNav.list" :key="index">
							<div class="item tr3" @click="changeNav(index)"
								:class="feedbackNav.current==index?'nav-select':''">
								{{item.name}}
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="tc ft24 pb40 mt30">反馈内容</div>
				<textarea placeholder="请填写15字以上的描述，以便我们更好的为您提供帮助！" class="text" v-model="content"></textarea>
				<div class="mt24">
					<!--<el-upload :file-list='picUrl' multiple :action="uploadUrl" list-type="picture-card" :auto-upload="false"  :on-success="handleAvatarSuccess"-->
							   <!--:before-upload="beforeAvatarUpload">-->
						<!--<i slot="default" class="el-icon-plus"></i>-->
						<!--<div slot="file" slot-scope="{file}">-->
							<!--<el-image style="height: 148px;" fit="cover" class="el-upload-list__item-thumbnail" :src="file.url"></el-image>-->
							<!--<span class="el-upload-list__item-actions">-->
								<!--<span class="el-upload-list__item-preview" @click="seePicUrlFn(file)">-->
									<!--<i class="el-icon-zoom-in"></i>-->
								<!--</span>-->
								<!--<span class="el-upload-list__item-delete" @click="delPicRrlFn(file)">-->
									<!--<i class="el-icon-delete"></i>-->
								<!--</span>-->
							<!--</span>-->
						<!--</div>-->
					<!--</el-upload>-->
					<multi-img :limit="10" :imgList.sync="multiImgList" :operationBtn="operationBtn" :multiImgList="multiImgList"></multi-img>
				</div>
				<div class="over pt34 pb50 mb30">
					<span class="ft24 fl">联系方式</span>
					<input class="ml16 el-border-bottom fl input" placeholder="请留下您的邮箱或电话，方便我们联系您~" v-model="phone"/>
				</div>
				<el-button type="primary" class="feedback-btn" @click="handleSubmit">提交反馈</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	import multiImg from '@/components/upload/multiImg.vue'
	import { saveFeedback } from '@/api/index.js'
	export default {
		components:{
			multiImg
		},
		data() {
			return {
				multiImgList: [],
				operationBtn:[1,1,0],
				feedbackNav: {
					list: [{name: '业务'}, {name: '建议'}, {name: '合作'}, {name: '吐槽'}],
					current: 0,
				},
				picUrl: [], // 图片列表
				seePicUrl: '', // 查看图片-url
				isSeePicUrl: false, // 查看图片-开关

				content: '', // 反馈内容
				phone: '', // 联系方式
				type: 1, // 反馈类型
				// feedbackImg: '', // 反馈图片
			}
		},
		methods: {
			//导航切换
			changeNav(index) {
				this.feedbackNav.current = index
				this.type = index + 1
			},

			handleSubmit(){
				if(this.content == ''){
					this.$message.warning('请填写反馈内容')
				}else if(this.phone == ''){
					this.$message.warning('请留下您的联系方式')
				}else{
					let feedbackImg = []
					this.multiImgList.forEach(item => {
						feedbackImg.push(item.response.data)
					})
					let data = {
						type: this.type,
						content: this.content,
						phone: this.phone,
						feedbackImg:feedbackImg.toString()
					}
					saveFeedback(data).then(res=>{
						if(res.code === 200){
							this.$message.success('您的反馈已收到，感谢您的反馈!')
						}
					})
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.inner {
		background-image: url(../assets/feedback-bg.png);
		background-position: center;
		background-size: cover;
		padding: 40px 0 40px;
	}

	.title-btn {
		height: 70px;
		background-color: #b4232f;
		border-radius: 50px;
		line-height: 70px;
		background-image: url(../assets/btn-bg.png);
		background-position: center;
		background-size: cover;
		margin-bottom: 40px;
	}

	.feedback-form {
		background-color: #ffffff;
		box-shadow: 3px 9px 3px 0px rgba(0, 0, 0, 0.16);
		padding: 52px 256px;
	}

	.news-nav .item {
		width: 100%;
		border-radius: 3px;
		border: 1px solid #bfbfbf;
		padding: 8px 0;
		margin-bottom: 20px;
		color: #666666;
		cursor: pointer;
	}

	.news-nav .item.nav-select,
	.news-nav .item:hover {
		border-color: #B5232E;
		background-color: #B5232E;
		color: #FFFFFF;
	}

	.text {
		width: 100%;
		height: 185px;
		background-color: #ededed;
		border-radius: 3px;
		box-sizing: border-box;
		padding: 25px;
		line-height: 1.6;
		font-size: 16px;
		resize: none;
	}

	.feedback-btn {
		width: 80%;
		height: 60px;
		border-radius: 28px;
		margin: 0 auto 40px;
		display: block;
		font-size: 20px;
	}

	.input {
		display: block;
		font-size: 18px;
		padding: 10px 10px;
		width: 78%;
	}
</style>
