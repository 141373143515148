<template>
  <div>
    <el-upload
      :action="uploadUrl"
      list-type="picture-card"
      :file-list="multiImgList"
      :limit="limit"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
      :on-exceed="onExceed">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <el-image
          :src="file.url"
          :preview-src-list="srcList"
          class="avatar el-upload-list__item-thumbnail"
        ></el-image>

        <span class="el-upload-list__item-actions">
        <span
          v-if="operationBtn[0] === 0"
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="operationBtn[1] === 0"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="operationBtn[2] === 0"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
      </div>
    </el-upload>
    <preview-img
      v-if="showPreviewImg"
      :showPreviewImg.sync="showPreviewImg"
      :srcList="srcList"
    ></preview-img>
  </div>
</template>

<script>
  import previewImg from '@/components/PreviewImg/index.vue'
  // import {downloadFile} from '@/assets/utils/index.js'
  export default {
    components:{
      previewImg
    },
    props:{
      multiImgList:{
        type:  Array,
        default: ()=>[]
      },
      limit: {
        type: Number,
        default: 1
      },
      operationBtn:{ // 查看、下载、删除按钮是否显示
        type: Array,
        default: ()=>[0,0,0]
      }
    },
    data() {
      return {
        dialogImageUrl: '',
        showPreviewImg: false,
        // imgList: [],
        uploadUrl: '/api/file/upload',
      };
    },
    methods: {
      handleAvatarSuccess(res, file) {
        if(res.code === 200){
          this.multiImgList.push(file)
          this.$emit('update:imgList',this.multiImgList)
        }
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
        }
        if (!isLt5M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
        }
        return isJPG && isLt5M;
      },
      handleRemove(file) {
        this.multiImgList.forEach((item, index) => {
          if(file.uid == item.uid){
            this.multiImgList.splice(index, 1)
          }
        })
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.showPreviewImg = true;
      },
      handleDownload(file) {
        //src是图片路径
        let eleLink = document.createElement('a');
        eleLink.download = file.url;
        eleLink.style.display = 'none';
        //字符内容转变成blob地址
        eleLink.href = file.url;
        //触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        //然后移除
        document.body.removeChild(eleLink);
      },
      onExceed(){
        this.$message.warning(`最多只能上传 ${this.limit} 个文件`);
      }
    },
    created(){

    },
    computed:{
      srcList(){
        let previewImgArray = []
        if(this.multiImgList && this.multiImgList.length > 0){
          this.multiImgList.forEach(item => {
            previewImgArray.push(item.url)
          })
        }
        return previewImgArray
      }
    },
  }
</script>

<style scoped>
  /deep/ .el-upload--picture-card{
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
  /deep/ .el-upload-list__item{
    width: 120px;
    height: 120px;
    margin: 0 8px -10px 0;
  }
  /deep/ .avatar{
    width: 120px;
    height: 120px;
  }
  /deep/ .hide{
    width: 120px;
    height: 120px;
  }
  /deep/ .hide .el-upload--picture-card {
    display: none;
  }
</style>

